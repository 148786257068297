import React from 'react'
import './methodology.css';
import conference from '../../assets/images/conferenceCover.png'
import { Link } from 'react-router-dom';

const Conference = ({ }) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant',
  });
    return (
        <div className="container" style={{paddingBottom:"35px"}}>
            <div >
              <h2 style={{marginTop:"15px"}}><strong>Annual Behavioral Health Update for Primary Care: Adolescents and Young Adults</strong></h2>
              <div className="column60" style={{padding:"0px"}}>
                <div className='faqQuestion' style={{marginTop:"35px"}}>
                    <div>
                        <h2 className='subtitle'>
                            <strong>Date</strong>
                        </h2>
                    </div>
                    <div>
                        <div>
                        <p style={{whitespace:"pre-wrap"}}>
                        March 29, 2025 
                        </p>
                        </div>
                    </div>
                </div>
                <div className='faqQuestion'>
                    <div>
                    <h2 className='subtitle'>
                            <strong>Location</strong>
                        </h2>
                    </div>
                    <div>
                        <div>
                            <p style={{whitespace:"pre-wrap"}}>
                            Waco Family Medicine<br/>
                            1600 Providence Drive,<br/>
                            Waco, TX 76707
                            </p>
                        </div>
                    </div>
                </div>
              </div>
              <div style={{width:"28%", float:"right"}}>
                <div className='faqQuestion' style={{marginTop:"35px"}}>
                    <img src={conference}/>
                </div>
              </div>
              <div className='faqQuestion'>
                  <div>
                  <h2 className='subtitle'>
                          <strong>Subscribe</strong>
                      </h2>
                  </div>
                  <div>
                      <div>
                      <p style={{whitespace:"pre-wrap"}}>
                        For email updates about the Annual Behavioral Health Update for Primary Care: Adolescents and Young Adults <a href='https://app.smartsheet.com/b/form/d63c20c806c24fb691d0876214e5b0f5' target="_blank" style={{textDecorationColor:"#696969"}}>click here to subscribe.</a>
                        </p>
                      </div>
                  </div>
              </div>
              <div className='faqQuestion'>
                  <div>
                  <h2 className='subtitle'>
                          <strong>Registration</strong>
                      </h2>
                  </div>
                  <div>
                      <div>
                        <p style={{whitespace:"pre-wrap"}}>
                        Thank you for being so interested in the Annual Behavioral Health Update! Please check back soon for more event details and registration options. 
                        </p>
                      </div>
                  </div>
              </div>
              <div className='faqQuestion'>
                  <div>
                  <h2 className='subtitle'>
                          <strong>description</strong>
                      </h2>
                  </div>
                  <div>
                      <div>
                        <p style={{whitespace:"pre-wrap"}}>
                        The Annual Behavioral Health Update for Primary Care is designed to provide healthcare professionals with an in-depth understanding of the primary care approach to evaluating and treating mental health disorders. This year's conference features topics in assessing and treating behavioral health disorders in adolescents and young adults. The program offers a comprehensive curriculum covering various topics, including the epidemiology, assessment, and treatment strategies for anxiety and mood disorders. With sessions led by experts from Massachusetts Hospital Visiting, participants will gain valuable insights into pharmacological and non-pharmacological treatment options and best practices for medication management. 
                        Participants are eligible for up to 6 hours of continuing education credits (CME for physicians, CE for social workers). 
                        </p>
                      </div>
                  </div>
              </div>
              <div className='faqQuestion'>
                  <div>
                      <h2 className='subtitle'>
                          <strong>1-hour Ethics Credit Available</strong>
                      </h2>
                  </div>
                  <div>
                      <div>
                      <p style={{whitespace:"pre-wrap"}}>
                      The event also explores the intersection of the humanities and mental health during a special ethics CME session. This program is an excellent opportunity for clinicians to enhance their knowledge and skills in managing mental health disorders while fulfilling continuing medical education requirements.
                      </p>
                      </div>
                  </div>
              </div>
            </div>
        </div>
    )
};

export default Conference