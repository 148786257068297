import React from 'react'
import './methodology.css';
import AdoGad from '../../assets/images/AdoGad.mp4'
import AdultGad from '../../assets/images/adultGadHep.mp4'
import AntepartumGad from '../../assets/images/antepartGad.mp4'
import { Link } from 'react-router-dom';

const Presenation = ({ }) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant',
  });
    return (
        <div className="container" style={{paddingBottom:"35px"}}>
            <div>
            <iframe src="https://wacofamilymedicine.sharepoint.com/sites/WacoGuide/_layouts/15/Doc.aspx?sourcedoc={02570a8a-959c-4d15-893a-4a3abb51a9cd}&amp;action=embedview&amp;wdAr=1.7777777777777777" width="100%" height="667px" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>    
            </div>

            <div className='grid3' style={{position:"relative"}}>
                <div>
                    <h4>Adolescent GAD</h4>
                    <video src={AdoGad} width='375' height='680' controls/>
                </div>
                <div>
                <h4>Adult GAD - Hepatic</h4>
                    <video src={AdultGad} width='375' height='680' controls/>
                </div>   
                <div>
                <h4>Antepartum GAD</h4>
                    <video src={AntepartumGad} width='375' height='680' controls/>
                </div>      
            </div>                                    
        </div>
    )
};

export default Presenation